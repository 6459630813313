import Link from "next/link";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "next-i18next";
import * as React from "react";

import Discord from "@/components/icons/discord.svg";
import Star from "@/components/icons/star.svg";
import Translate from "@/components/icons/translate.svg";
import Twitter from "@/components/icons/twitter.svg";
import DigitalOcean from "~/public/digitalocean.svg";
import Logo from "~/public/logo.svg";
import Sentry from "~/public/sentry.svg";
import Vercel from "~/public/vercel-logotype-dark.svg";

import { LanguageSelect } from "../poll/language-selector";

const Footer: React.VoidFunctionComponent = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  return (
    <div className="mt-16 bg-gradient-to-b from-gray-50/0 via-gray-50 to-gray-50 ">
      <div className="mx-auto max-w-7xl space-y-8 p-8 lg:flex lg:space-x-16 lg:space-y-0">
        <div className=" lg:w-2/6">
        </div>
        <div className="lg:w-1/6">
          <div className="mb-4 font-medium">{t("links")}</div>
          <ul className="space-y-2">
            <li>
              <Link href="/privacy-policy">
                <a className="inline-block font-normal text-slate-400 hover:text-slate-800 hover:no-underline">
                  {t("privacyPolicy")}
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="lg:w-2/6">
          <div className="mb-4 font-medium">{t("language")}</div>
          <LanguageSelect
            className="mb-4 w-full"
            onChange={(locale) => {
              router.push(router.asPath, router.asPath, { locale });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
